import Script from 'next/script'

interface ScriptsProps {
  nonce: string
}

const Scripts: React.FC<ScriptsProps> = ({ nonce }) => {
  return (
    <>
      {/* GOOGLE MAPS */}
      <Script
        src={`https://maps.googleapis.com/maps/api/js?key=${process.env.NEXT_PUBLIC_GOOGLE_API_KEY}&libraries=places`}
        nonce={nonce}
      />

      {/* GOOGLE ANALYTICS */}
      <Script id='google-analytics'>
        {`(function (i, s, o, g, r, a, m) {
        i['GoogleAnalyticsObject'] = r; i[r] = i[r] || function () {
          (i[r].q = i[r].q || []).push(arguments)
        }, i[r].l = 1 * new Date(); a = s.createElement(o),
          m = s.getElementsByTagName(o)[0]; a.async = 1; a.src = g; m.parentNode.insertBefore(a, m)
      })(window, document, 'script', '//www.google-analytics.com/analytics.js', 'ga');
      ga('create', 'UA-61742288-2', 'auto');
      ga('send', 'pageview');`}
      </Script>

      {/* TRUSTPILOT */}
      <Script
        type='text/javascript'
        src='//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js'
        async
        nonce={nonce}
      />

      {/* HUBSPOT */}
      <Script
        type='text/javascript'
        id='hs-script-loader'
        async
        defer
        src={`//js.hs-scripts.com/${process.env.NEXT_PUBLIC_HUBSPOT_PLATFORM}.js`}
        nonce={nonce}
      />
      {/* KAMELEOON */}
      {process.env.NEXT_PUBLIC_REACT_APP_ENVIRONMENT !== 'development' && (
        <>
          <Script
            type='text/javascript'
            src='/scripts/Kameleoon/kameleoon.js'
            async={true}
            nonce={nonce}
          />
          <Script
            type='text/javascript'
            src='//pb55kayfq8.kameleoon.io/kameleoon.js'
            async={true}
            nonce={nonce}
          />
        </>
      )}
    </>
  )
}

export default Scripts
