import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '@/app/store'
import {
  entryModule,
  Informations,
  Summary,
  Welcome
} from '@/workflows/modules'
import { generateFlow, findModule, flowUntil } from '@/workflows/utils'

export type FlowType = 'create_or_transfert' | 'create' | 'transfert'

export type CountryType = 'FR' | 'BE'

export interface WorkflowState {
  path: string
  flowType: FlowType
  flow: string[]
  paid?: boolean
  country?: CountryType
}

const initialState: WorkflowState = {
  path: '/project',
  flowType: 'create_or_transfert',
  flow: [entryModule().path],
  paid: false,
  country: 'FR'
}

export const workflowSlice = createSlice({
  name: 'workflow',
  initialState,
  reducers: {
    nextPage: (state, action: PayloadAction<string | undefined>) => {
      const currentModule = findModule(state.path)!
      const nextModule = currentModule.nextPage(action?.payload)

      if (nextModule) {
        state.flow = generateFlow(state.flow, state.path, nextModule.path)
        state.path = nextModule.path
      }
    },
    goToWelcome: (state) => {
      state.path = Welcome.path
      state.flow = flowUntil(state.flowType, Welcome)
    },
    goToSummary: (state) => {
      state.path = Summary.path
      state.flow = flowUntil(state.flowType, Summary)
    },
    goToInformations: (state) => {
      state.path = Informations.path
      state.flow = flowUntil(state.flowType, Informations)
    },
    previousPage: (state) => {
      const previousPathIndex = state.flow.indexOf(state.path) - 1
      if (previousPathIndex >= 0) state.path = state.flow.at(previousPathIndex)!
      else if (!state.flow.includes(state.path)) state.path = state.flow.at(-1)!
    },
    setFlowType: (state, action: PayloadAction<FlowType>) => {
      state.flowType = action.payload
    },
    setPath: (state, action: PayloadAction<string>) => {
      state.path = action.payload
    },
    setPaid: (state, action: PayloadAction<boolean>) => {
      state.paid = action.payload
    },
    setCountry: (state, action: PayloadAction<CountryType>) => {
      state.country = action.payload
    },
    setEntryModule: (state) => {
      state.flow = [entryModule().path]
      state.path = entryModule().path
    }
  }
})

export const {
  nextPage,
  previousPage,
  setEntryModule,
  setFlowType,
  setPath,
  setPaid,
  setCountry,
  goToWelcome,
  goToInformations,
  goToSummary
} = workflowSlice.actions
export const selectWorkflow = (state: RootState) => state.workflow

export default workflowSlice.reducer
