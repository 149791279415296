import React from 'react'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import BugsnagPerformance from '@bugsnag/browser-performance'

const BugsnagWrapper = ({ children }: { children: React.ReactNode }) => {
  Bugsnag.start({
    apiKey: process.env.NEXT_PUBLIC_BUGSNAG_ID!,
    appVersion: process.env.NEXT_PUBLIC_PACKAGE_VERSION,
    plugins: [new BugsnagPluginReact()],
    releaseStage: process.env.NEXT_PUBLIC_REACT_APP_ENVIRONMENT,
    onError: (event) => {
      event.setUser(sessionStorage.getItem('prospectId') || undefined)
    },
    enabledReleaseStages: ['production', 'staging']
  })
  BugsnagPerformance.start({ apiKey: process.env.NEXT_PUBLIC_BUGSNAG_ID! })

  const ErrorBoundary = Bugsnag.getPlugin('react')!.createErrorBoundary(React)

  return <ErrorBoundary>{children}</ErrorBoundary>
}

export default BugsnagWrapper
