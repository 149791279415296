'use client'

import '@sedomicilier/material-sd/src/globals.css'
import '@/app/icons.css'
import './style.css'
import { publicoFont, icomoonFont, inter } from '@/app/fonts'
import { setupStore } from '@/app/store'
import { persistStore } from 'redux-persist'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import Scripts from '@/app/layout/scripts'
import BugsnagWrapper from '@/app/layout/bugsnag'
import CountryFlow from '@/app/layout/CountryFlow'

interface ClientWrapperProps {
  children: React.ReactNode
  nonce: string
}

const ClientWrapper: React.FC<ClientWrapperProps> = ({ children, nonce }) => {
  const store = setupStore()
  const persistor = persistStore(store)

  return (
    <BugsnagWrapper>
      <Provider store={store}>
        <html
          className={`${icomoonFont.className} ${publicoFont.variable} ${inter.variable}`}
        >
          <head>
            <title>SeDomicilier</title>
            <meta name='robots' content='noindex,nofollow' />
            <meta name='googlebot' content='noindex,nofollow' />
            <meta
              name='viewport'
              content='width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no'
            />
            <meta
              name='google'
              content='nositelinkssearchbox'
              key='sitelinks'
            />
            <meta name='google' content='notranslate' key='notranslate' />
            <Scripts nonce={nonce} />
          </head>
          <body className='layout commanderImg'>
            <PersistGate loading={null} persistor={persistor}>
              <CountryFlow>{children}</CountryFlow>
            </PersistGate>
          </body>
        </html>
      </Provider>
    </BugsnagWrapper>
  )
}

export default ClientWrapper
