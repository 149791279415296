export interface ModuleType {
  name: string
  path: string
  redirectPage: boolean
  nextPage: (option?: string) => ModuleType | undefined
}

export const Address: ModuleType = {
  name: 'address',
  path: '/address',
  redirectPage: false,
  nextPage: () => MailsOptions
}

export const Billing: ModuleType = {
  name: 'billing',
  path: '/payment/billing',
  redirectPage: false,
  nextPage: () => BillingProcessing
}

export const BillingProcessing: ModuleType = {
  name: 'billingProcessing',
  path: '/payment/processing',
  redirectPage: true,
  nextPage: () => Welcome
}

export const Error: ModuleType = {
  name: 'error',
  path: '/error',
  redirectPage: true,
  nextPage: () => undefined
}

export const Formalities: ModuleType = {
  name: 'formalities',
  path: '/formalities',
  redirectPage: false,
  nextPage: (option) => (option === 'micro' ? Micro : Summary)
}

export const Informations: ModuleType = {
  name: 'informations',
  path: '/informations',
  redirectPage: false,
  nextPage: (option) =>
    option === 'summary' ? Summary : option === 'micro' ? Micro : Formalities
}

export const LegalStatus: ModuleType = {
  name: 'legalStatus',
  path: '/legal-status',
  redirectPage: false,
  nextPage: () => Address
}

export const MailsOptions: ModuleType = {
  name: 'mailsOptions',
  path: '/mails-options',
  redirectPage: false,
  nextPage: (option) =>
    option === 'summary'
      ? Summary
      : option === 'micro'
        ? Micro
        : option === 'formality'
          ? Formalities
          : Informations
}

export const Micro: ModuleType = {
  name: 'bic-bnc',
  path: '/bic-bnc',
  redirectPage: false,
  nextPage: (option) => (option === 'legal-status' ? LegalStatus : Summary)
}

export const Payment: ModuleType = {
  name: 'payment',
  path: '/payment/summary',
  redirectPage: false,
  nextPage: () => Billing
}

export const Project: ModuleType = {
  name: 'project',
  path: '/project',
  redirectPage: false,
  nextPage: (option) => (option === 'transfert' ? Society : LegalStatus)
}

export const Society: ModuleType = {
  name: 'society',
  path: '/society',
  redirectPage: false,
  nextPage: () => Address
}

export const Summary: ModuleType = {
  name: 'summary',
  path: '/summary',
  redirectPage: false,
  nextPage: () => Payment
}

export const Welcome: ModuleType = {
  name: 'welcome',
  path: '/welcome',
  redirectPage: false,
  nextPage: () => undefined
}

export const entryModule = (): ModuleType => {
  return Project
}

export const modules: ModuleType[] = [
  Address,
  Billing,
  BillingProcessing,
  Error,
  Formalities,
  Informations,
  LegalStatus,
  MailsOptions,
  Micro,
  Payment,
  Project,
  Society,
  Summary,
  Welcome
]

export const domiciliationMainSteps = (flowType: string): ModuleType[] => {
  switch (flowType) {
    case 'create' || 'create_with_formality':
      return [Project, LegalStatus, Address, MailsOptions]
    case 'transfert' || 'transfert_with_formality':
      return [Project, Society, Address, MailsOptions]
    default:
      return []
  }
}

export const informationsMainSteps = (
  _flowType: string,
  isMicro?: boolean
): ModuleType[] => {
  return isMicro
    ? [Informations, Formalities, Micro, Summary]
    : [Informations, Formalities, Summary]
}

export const paymentMainSteps = (_flowType: string): ModuleType[] => [
  Payment,
  Billing
]

export const createSteps = [LegalStatus, Address, MailsOptions, Informations]

export const transfertSteps = [Society, Address, MailsOptions]

export const paymentSteps = [Summary, Payment, Billing]
